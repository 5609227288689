<template>
    <div>
      <nav-bar></nav-bar>
      <div class="d-flex justify-content-center align-items-center">
      <div class="space">
      <div class="row">
          <!-- Contact form or information goes here -->
          <div class="container py-5">
    <h1 class="text-center mb-4">Advertising Packages</h1>
    <p class="text-center text-muted mb-5">
      Boost your business with our tailored advertising solutions. Choose the package that fits your needs and reach your target audience effectively.
    </p>

    <!-- Package Cards -->
    <div class="row">
      <!-- Basic Package -->
      <div class="col-md-4 mb-4">
        <div class="card shadow-sm h-100">
          <div class="card-header bg-primary text-white text-center">
            <h3>Basic Package</h3>
          </div>
          <div class="card-body">
            <p class="text-muted text-center">Ideal for startups and small businesses.</p>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">Up to 10,000 impressions</li>
              <li class="list-group-item">Ad placement: Sidebar</li>
              <li class="list-group-item">Duration: 1 Month</li>
              <li class="list-group-item">Price: $100</li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Standard Package -->
      <div class="col-md-4 mb-4">
        <div class="card shadow-sm h-100">
          <div class="card-header bg-success text-white text-center">
            <h3>Standard Package</h3>
          </div>
          <div class="card-body">
            <p class="text-muted text-center">Perfect for medium-sized businesses.</p>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">Up to 50,000 impressions</li>
              <li class="list-group-item">Ad placement: Header or Footer</li>
              <li class="list-group-item">Duration: 3 Months</li>
              <li class="list-group-item">Price: $250</li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Premium Package -->
      <div class="col-md-4 mb-4">
        <div class="card shadow-sm h-100">
          <div class="card-header bg-warning text-white text-center">
            <h3>Premium Package</h3>
          </div>
          <div class="card-body">
            <p class="text-muted text-center">Best for large enterprises.</p>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">Unlimited impressions</li>
              <li class="list-group-item">Ad placement: Homepage banner</li>
              <li class="list-group-item">Duration: 6 Months</li>
              <li class="list-group-item">Price: $500</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Contact Us Section -->
    <div class="text-center mt-5">
      <h5>Interested in Advertising?</h5>
      <p class="text-muted">Contact us for more details and personalized packages tailored to your needs.</p>
      <router-link class="btn btn-outline-primary" to="/contact">Contact Us</router-link>
    </div>
  </div> 
        
      </div>  
    </div>  
  </div>
      <footer-big></footer-big>
      <footer-bar></footer-bar>
    </div>
  </template>
  
  <script>
  import NavBar from '@/components/NavBar.vue';
  import FooterBar from '@/components/FooterBar.vue';
  import FooterBig from '@/components/FooterBig.vue';

  export default {
    name: 'ServiceSes',
    components: {
      NavBar,
      FooterBar,
      FooterBig,
    },
  };
  </script>
  <style scoped>
  .space
  {
    padding:50px 0 50px 0;
  }
  .card {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}
.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}
  </style>
  