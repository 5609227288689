<template>
    <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <!-- Logo and About Section -->
        <div class="col-md-3">
          <img :src="require('@/assets/logo.png')" alt="The CityBits Logo" class="footer-logo">
          <p class="footer-about-text">
            "TheCityBits" seamlessly conects you to the pulse of your city with real-time news and local service bookings at your fingertips.
          </p>
        </div>
        <!-- Categories -->
        <div class="col-md-3">
          <h5 class="footer-heading">Categories</h5>
          <ul class="footer-links">
            <li><a href="#">Business & Economy</a></li>
            <li><a href="#">Crime & Safety</a></li>
            <li><a href="#">Technology</a></li>
            <!-- Add other categories -->
          </ul>
        </div>
        <!-- Get in touch -->
        <div class="col-md-3">
          <h5 class="footer-heading">Get in touch</h5>
          <ul class="footer-links">
            <li><a href="#">Privacy policy</a></li>
            <li><a href="#">Terms of Service</a></li>
<!-- Add other links -->
          </ul>
          <!-- Social Links -->
          <h5 class="footer-heading">Share & Follow</h5>
          <div class="social-links">
          <!-- Use icons for social links -->
          <a href="https://www.facebook.com/profile.php?id=61552762950163" class="social-link"><i class="fab fa-facebook-f"></i></a>
          <a href="#" class="social-link"><i class="fab fa-twitter"></i></a>
          <a href="#" class="social-link"><i class="fab fa-youtube"></i></a>
          <a href="#" class="social-link"><i class="fab fa-instagram"></i></a>
          </div>
</div>
<!-- Newsletter Signup -->
<div class="col-md-3">
<h5 class="footer-heading">Get Insider Updates And Promotions!</h5>
<form class="newsletter-form">
<input type="email" class="form-control" placeholder="Enter your Email here**">
<button type="submit" class="btn btn-primary">Subscribe Now</button>
</form>
</div>
</div>
</div>
  </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterBig'
  };
  </script>
  
  <style scoped>
  .footer
{
background-color: #000;
color: #fff; 
padding: 20px 0;
}

.footer-logo {
margin-bottom: 0px; /* Logo bottom margin */
height:150px;
}

.footer-about-text {
color: #aaa; /* About section text color */
}

.footer-heading {
  padding-top: 30px;
  color: #fff; /* Headings color /
  margin-bottom: 15px; / Headings bottom margin */
}

.footer-links {
list-style: none; /* Remove default list styling */
padding-left: 0; 
}

.footer-links li a {
color: #aaa; 
text-decoration: none;
transition: color 0.3s;
font-size: 14px;
}

.footer-links li a:hover {
color: #fff; /* Links color on hover */
}

.social-links {
margin-bottom: 20px; /* Social links bottom margin */
}

.social-link {
display: inline-block; 
margin-right: 15px; 
color: #45bfde; 
transition: color 0.3s; 
font-size: 24px;
}

.social-link:hover {
color: #fff; /* Social links color on hover */
}

.newsletter-form .form-control {
margin-bottom: 10px; /* Input bottom margin */
}

.newsletter-form .btn {
width: 100%; /* Button width */
}
  </style>
  