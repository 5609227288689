<template>
    <div class="loader-container" v-show="isLoading">
      <div class="loader"></div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isLoading: Boolean
    }
  }
  </script>
  
  <style scoped>
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* Full viewport height */
    height: 100vh; 
  }
  
  .loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  </style>
  