<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-2 d-flex flex-column justify-content-center align-items-end p-0">
        <div class="breaking-news-top">
          <h4>Breaking</h4>  
        </div>
      </div>
      <div class="col-md-7">
        <div id="carouselExampleCaptions1" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item" v-for="(item, index) in items" :key="index" :class="{ active: index === 0 }" data-bs-interval="3000">        
              <div class="breaking-link carousel-caption d-flex justify-content-start align-items-center">
                <a :href="`/news/${item.category}/${item.slug}`">
                  <h5 class="slide-up">{{ item.title }}</h5>
                </a>  
              </div>
            </div>      
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <!-- Weather -->
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import { db } from '@/firebase';

export default {
  name: 'BreakingNews',
  data() {
    return {
      items: []
    };
  },
  async created() {
    await this.fetchNewsFromFirebase();
  },
  methods: {
    async fetchNewsFromFirebase() {
      try {
        const querySnapshot = await db.collection('general').orderBy('published_at', 'desc').limit(10).get();
        this.items = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      } catch (error) {
        console.error('Error fetching news from Firebase:', error);
        // Handle the error appropriately
      }
    },
  }
};
  </script>
  
  <style scoped>
  /* Custom style for the carousel */
  .carousel-item {
    height: 100px;
    position: relative;
  }

  .carousel-caption {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: left;
    justify-content: center;
  }

  .slide-up {
    animation: slideUp 0.5s ease forwards;
  }

  @keyframes slideUp {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  h5
  {
    font-size: 14px;    
    color:#000;
    font-weight: 700;
    margin-bottom: 0px !important;
  }
/* .breaking-news-top
{
  background-color: #45bfde;
  padding: 12px 40px 5px 37px;
  border-left:red 10px solid;
}
  h4
  {
    font-size: 20px;    
    color:#fff;
    font-weight: 700;
    text-transform: uppercase;
  } */

  .breaking-news-top {
  background-color: #45bfde;
  padding: 12px 40px 12px 50px; /* Left padding adjusted to accommodate the arrow */
  position: relative;
  border-left: none; /* Assuming you want only the arrow without a solid left border */
}

.breaking-news-top:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 30px; /* Adjusted for a more visible arrow */
  background: #ff3333; /* Vibrant red that complements the background */
  clip-path: polygon(0 0, 100% 50%, 0 100%); /* This makes the arrow pointy */
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.4); /* Adds shadow to the arrow */
}

h4 {
  font-size: 20px;    
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0; /* Ensures the text does not add unnecessary space around it */
}

.breaking-link a{
  text-decoration: none;
}

</style>
  