<template>
  <div>
    <LoaderSpin :isLoading="isLoading" />
    <nav-bar></nav-bar>
    <breaking-news></breaking-news>
    <hero-section></hero-section>
    <news-section></news-section>   
    <local-news></local-news>
    <national-news></national-news>   
    <bes-news></bes-news>
    <footer-big></footer-big>
    <footer-bar></footer-bar>
  </div>
</template>

<script>
import LoaderSpin from '@/components/LoaderSpin.vue';
import NavBar from '@/components/NavBar.vue';
import BreakingNews from '@/components/BreakingNews.vue';
import HeroSection from '@/components/HeroSection.vue';
import NewsSection from '@/components/NewsSection.vue';
import FooterBar from '@/components/FooterBar.vue';
import NationalNews from '@/components/NationalNews.vue';
import LocalNews from '@/components/LocalNews.vue';
import BesNews from '@/components/BesNews.vue';
import FooterBig from '@/components/FooterBig.vue';

export default {
  components: {
  NavBar,
  BreakingNews,
  HeroSection,
  NewsSection,
  FooterBar,
  NationalNews,
  LocalNews,
  BesNews,
  FooterBig,
  LoaderSpin
},
data() {
    return {
      isLoading: true,
      // other data properties
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      // Fetch your data here
      // After fetching data, set isLoading to false
      setTimeout(() => {
    // Simulate fetch delay
    this.isLoading = false;
  }, 600); // 1-second delay
    }
  }
}
</script>  