<template>
  <div class="row">
    <div class="col-6 p-0">
      <div id="carouselMainFeature" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" 
                  v-for="(item, index) in items" 
                  :key="'indicator-' + index"
                  data-bs-target="#carouselMainFeature" 
                  :data-bs-slide-to="index" 
                  :class="{ active: index === 0 }" 
                  :aria-label="'Slide ' + (index + 1)">
          </button>          
        </div>
        <div class="carousel-inner">
          <div class="carousel-item" v-for="(item, index) in items" :key="index" :class="{ active: index === 0 }" data-bs-interval="4500">
            <div class="overlay"></div>
            <img :src="item.image" class="d-block w-100" alt="...">
            <div class="carousel-caption d-flex justify-content-center align-items-center">
              <a :href="`/news/${item.category}/${item.slug}`">
                <h5 class="slide-up">{{ item.title }}</h5>
              </a>
            </div>
          </div>      
        </div>
      </div>  
      <h4 class="carousel-title">National</h4>
    </div>
    <div class="col-6 p-0">  
      <div id="carouselMainFeaturei" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" 
                  v-for="(item, index) in newsi" 
                  :key="'indicator-' + index"
                  data-bs-target="#carouselMainFeaturei" 
                  :data-bs-slide-to="index" 
                  :class="{ active: index === 0 }" 
                  :aria-label="'Slide ' + (index + 1)">
          </button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item" v-for="(item, index) in newsi" :key="index" :class="{ active: index === 0 }" data-bs-interval="4500">
            <div class="overlay"></div>
            <img :src="item.image" class="d-block w-100" alt="...">
            <div class="carousel-caption d-flex justify-content-center align-items-center">
              <a :href="`/news/${item.category}/${item.slug}`">
                <h5 class="slide-up">{{ item.title }}</h5>
              </a>
            </div>
          </div>      
        </div>
      </div>  
      <h4 class="carousel-titleii">Science</h4>
    </div>
  </div>
  </template>
  
  <script>
  import { db } from '@/firebase';
  export default {
  name: 'HeroSection',
  data() {
    return {
      items: [],
      newsi: []
    };
  },
  async created() {
    await this.fetchNewsFromFirebase();
    await this.fetchNewsFromFirebasei();
  },
  methods: {
    async fetchNewsFromFirebase() {
      try {
        const querySnapshot = await db.collection('general').where('country', '==', 'us').orderBy('published_at', 'desc').limit(6).get();
       // const querySnapshot = await db.collection('general').orderBy('published_at', 'desc').limit(6).get();
        this.items = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      } catch (error) {
        console.error('Error fetching news from Firebase:', error);
        // Handle the error appropriately
      }
    },
    async fetchNewsFromFirebasei() {
      try {
        const querySnapshot = await db.collection('science').orderBy('published_at', 'desc').limit(6).get();
        this.newsi = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      } catch (error) {
        console.error('Error fetching news from Firebase:', error);
        // Handle the error appropriately
      }
    },
    formattedDate(dateString) {
      const dateObject = new Date(dateString);
      return this.formatDate(dateObject);
    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString('en-US', options);
    },
    truncateText(text, limit) {
      const wordsArray = text.split(' ');
      if (wordsArray.length > limit) {
        return wordsArray.slice(0, limit).join(' ') + '...';
      }
      return text;
    },
  },
  }
  </script>
  
  <style scoped>
  a{
    text-decoration: none;
    color: inherit; /* To retain the text color */
    display: flex; /* Make the anchor tag a flex container */
    align-items: center; /* Align the items vertically in the center */
}
  /* Custom style for the carousel */
  .carousel-item {
    height: 500px;
    position: relative;
  }

  /* Style for the images to cover the 300px height */
  .carousel-item img {
    height: 550px;
    object-fit: cover;
    width: 100%;
  }

  .carousel-caption {
    display: flex;
    align-items: center;
    justify-content: center;
  }

    .slide-up {
      
      animation: slideUp 0.5s ease forwards;
    }

  @keyframes slideUp {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3); /* Black overlay with opacity */
  }

  h5
  {
    font-size: 20px;    
  }

  .carousel-title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 10%;
  background-color: #45bfde;
  color: white;
  text-align: center;
  padding: 5px 0; /* Add some padding to give space around the text */
  margin: 0; /* Remove default margin of h4 */
}
.carousel-titleii {
  position: absolute;
  bottom: 0;
  left: 1;
  width: 10%;
  background-color: #45bfde;
  color: white;
  text-align: center;
  padding: 5px 0; /* Add some padding to give space around the text */
  margin: 0; /* Remove default margin of h4 */
}
h4 {
  font-size: 14px; /* Adjust as per your requirement */
  color: #fff;
  text-transform: uppercase;
  margin: 0; /* Ensures the text does not add unnecessary space around it */
  padding: 5px 0px 5px 15px;
  width:98px;
}
</style>
  