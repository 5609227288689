<template>
<div class="container-fluid">
  <div class="row">
      <!-- First Column -->
      <div class="col-md-6">
        <h3><span class="bes-text-background">Technology</span></h3>
        <div class="content-block d-flex mb-4" v-for="(content, index) in contentsColumn1" :key="index">
          <a :href="`/news/${content.category}/${content.slug}`" class="d-flex align-items-center text-decoration-none">  
            <img :src="content.image" class="content-image img-fluid">          
            <div class="content-text ms-3">
              <h5>{{ content.title }}</h5>
              <p>{{ truncateText(content.description, 20) }}</p>
              <span class="date"><i class="fas fa-calendar"></i>{{ formattedDate(content.published_at) }}</span>
            </div>
          </a>  
        </div>
      </div>

      <!-- Second Column -->
      <div class="col-md-6">        
        <h3><span class="bes-text-background">Sports</span></h3>
        <div class="content-block d-flex mb-4" v-for="(content, index) in contentsColumn2" :key="index">
          <a :href="`/news/${content.category}/${content.slug}`" class="d-flex align-items-center text-decoration-none">  
            <img :src="content.image" class="content-image img-fluid">
            <div class="content-text ms-3">
              <h5>{{ content.title }}</h5>
              <p>{{ truncateText(content.description, 20) }}</p>
              <span class="date"><i class="fas fa-calendar"></i>{{ formattedDate(content.published_at) }}</span>
            </div>
          </a>  
        </div>
      </div>
    </div>
</div>
</template>
<script>
import { db } from '@/firebase';
export default{
    name: 'BesNews',
    data() {
    return {
      contentsColumn1: [],
      contentsColumn2: [],
    };
  },
  async created() {
    await this.fetchNewsFromFirebase();
    await this.fetchNewsFromFirebasei();
  },
  methods: {
    async fetchNewsFromFirebase() {
      try {
        const querySnapshot = await db.collection('technology').orderBy('published_at', 'desc').limit(4).get();
        this.contentsColumn1 = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      } catch (error) {
        console.error('Error fetching news from Firebase:', error);
        // Handle the error appropriately
      }
    },
    async fetchNewsFromFirebasei() {
      try {
        const querySnapshot = await db.collection('sports').orderBy('published_at', 'desc').limit(4).get();
        this.contentsColumn2 = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      } catch (error) {
        console.error('Error fetching news from Firebase:', error);
        // Handle the error appropriately
      }
    },
    formattedDate(dateString) {
      const dateObject = new Date(dateString);
      return this.formatDate(dateObject);
    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString('en-US', options);
    },
    truncateText(text, limit) {
      const wordsArray = text.split(' ');
      if (wordsArray.length > limit) {
        return wordsArray.slice(0, limit).join(' ') + '...';
      }
      return text;
    }
  }
}
</script>
<style scoped>
a{
    text-decoration: none;
    color: inherit; /* To retain the text color */
    display: flex; /* Make the anchor tag a flex container */
    align-items: center; /* Align the items vertically in the center */
    width: 100%; /* Full width to make entire area clickable */
}
.content-block {
  align-items: center; /* Aligns the image and content vertically */
}

.content-text {
  flex-grow: 1; /* Allows the text content to fill the remaining space */
}

.date
{
  font-size:10px;
}

h3
{
  padding:0px 0px 0px 0px;
  margin:30px 0px 20px 0px;
  border-bottom: #45bfde 2px solid;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 30px;
}
.bes-text-background
{
  background-color: #45bfde;
  color:#fff;
  padding:5px 15px 5px 15px;
  margin-bottom: 20px;
}
h5{
font-size: 17px;
font-weight: 600;
}
.content-image {
  height: 150px;
  object-fit: cover;
  max-width: 150px; /* Adjust as needed */
  flex-shrink: 0; /* Prevent the image from shrinking */
}

.content-text {
  flex-grow: 1; /* Allow the text content to take up the remaining space */
}
.fas.fa-calendar {
  color: #45bfde; /* Bootstrap primary color */
  margin-right: 5px; /* Spacing between icon and text */
}
</style>