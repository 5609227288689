// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDL_tiHBb-3ceZNj_4l7T6c31oCJHHQy2E",
  authDomain: "citybits-5271f.firebaseapp.com",
  projectId: "citybits-5271f",
  storageBucket: "citybits-5271f.appspot.com",
  messagingSenderId: "395022395537",
  appId: "1:395022395537:web:b5e634c9f0d4a80389f730",
  measurementId: "G-31B52T2914"
};

firebase.initializeApp(firebaseConfig);

// Enable offline persistence
firebase.firestore().enablePersistence()
  .catch((err) => {
    if (err.code == 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
    } else if (err.code == 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
    }
  });
  
// Initialize Firestore and export it
const db = firebase.firestore();
export { db };

