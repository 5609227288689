<template>   
  <nav-bar></nav-bar>
   
  <div class="container py-5">
    <h1 class="text-center mb-4">Book an Appointment</h1>

    <!-- Appointment Form -->
    <form v-if="!selectedService" @submit.prevent="findServices" class="mb-5">
      <div class="row">
        <!-- Date Selector -->
        <div class="col-md-4 mb-3">
          <label for="date" class="form-label">Select Date</label>
          <input
            type="date"
            id="date"
            v-model="appointmentData.date"
            class="form-control"
            required
          />
        </div>

        <!-- Service Type -->
        <div class="col-md-4 mb-3">
          <label for="serviceType" class="form-label">Service Type</label>
          <select
            id="serviceType"
            v-model="appointmentData.serviceType"
            class="form-select"
            required
          >
            <option value="" disabled>Select a service</option>
            <option v-for="service in services" :key="service.id" :value="service.name">
              {{ service.name }}
            </option>
          </select>
        </div>

        <!-- City or Postal Code -->
        <div class="col-md-4 mb-3">
          <label for="location" class="form-label">City or Postal Code</label>
          <input
            type="text"
            id="location"
            v-model="appointmentData.location"
            class="form-control"
            placeholder="Enter city name or postal code"
            required
          />
        </div>
      </div>

      <div class="text-center">
        <button type="submit" class="btn btn-primary">Find Services</button>
      </div>
    </form>

    <!-- Results Section -->
    <div v-if="availableServices.length && !selectedService" class="mt-4">
      <h3 class="text-center">Available Services</h3>
      <div class="row">
        <div
          v-for="service in availableServices"
          :key="service.id"
          class="col-md-4 mb-4"
        >
          <div class="card h-100 shadow-sm">
            <div class="card-body text-center">
              <h5 class="card-title">{{ service.name }}</h5>
              <p class="card-text">{{ service.description }}</p>
              <button class="btn btn-success" @click="selectService(service)">
                Book Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Appointment Details Form -->
    <div v-else-if="selectedService" class="mt-5">
      <h3 class="text-center">Complete Your Appointment</h3>
      <form @submit.prevent="submitAppointment">
        <div class="row mb-3">
          <div class="col-md-6">
            <label for="firstName" class="form-label">First Name</label>
            <input
              type="text"
              id="firstName"
              v-model="appointmentDetails.firstName"
              class="form-control"
              required
            />
          </div>
          <div class="col-md-6">
            <label for="lastName" class="form-label">Last Name</label>
            <input
              type="text"
              id="lastName"
              v-model="appointmentDetails.lastName"
              class="form-control"
              required
            />
          </div>
        </div>
        <div class="mb-3">
          <label for="email" class="form-label">Email Address</label>
          <input
            type="email"
            id="email"
            v-model="appointmentDetails.email"
            class="form-control"
            required
          />
        </div>
        <div class="mb-3">
          <label for="phone" class="form-label">Phone Number</label>
          <input
            type="tel"
            id="phone"
            v-model="appointmentDetails.phone"
            class="form-control"
            required
          />
        </div>
        <div class="mb-3">
          <label for="notes" class="form-label">Additional Notes</label>
          <textarea
            id="notes"
            v-model="appointmentDetails.notes"
            class="form-control"
            rows="3"
          ></textarea>
        </div>
        <div class="text-center">
          <button type="submit" class="btn btn-primary">Submit Appointment</button>
        </div>
      </form>
    </div>

    <!-- No Results Message -->
    <div v-else-if="submitted && !availableServices.length" class="text-center mt-4">
      <p class="text-muted">No services available for the selected criteria.</p>
    </div>
  </div>
  
  <footer-big></footer-big>
  <footer-bar></footer-bar>   
</template>
  
  <script>
  import NavBar from '@/components/NavBar.vue';
  import FooterBar from '@/components/FooterBar.vue';
  import FooterBig from '@/components/FooterBig.vue';

  export default {
    name: 'AppointmentBook',
    components: {
      NavBar,
      FooterBar,
      FooterBig,
    },
    data() {
    return {
      services: [
        { id: 1, name: "Consultation" },
        { id: 2, name: "Therapy Session" },
        { id: 3, name: "Follow-Up" },
      ],
      appointmentData: {
        date: "",
        serviceType: "",
        location: "",
      },
      availableServices: [],
      selectedService: null,
      appointmentDetails: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        notes: "",
      },
      submitted: false,
    };
  },
  methods: {
    findServices() {
      this.submitted = true;

      // Mock logic to filter services
      if (
        this.appointmentData.date &&
        this.appointmentData.serviceType &&
        this.appointmentData.location
      ) {
        this.availableServices = [
          {
            id: 1,
            name: "Morning Session",
            description: "Available from 9:00 AM to 11:00 AM",
          },
          {
            id: 2,
            name: "Evening Session",
            description: "Available from 5:00 PM to 7:00 PM",
          },
        ];
      } else {
        this.availableServices = [];
      }
    },
    selectService(service) {
      this.selectedService = service;
    },
    submitAppointment() {
      alert("Appointment successfully booked!");
      console.log("Appointment Details:", this.appointmentDetails);
      this.resetForm();
    },
    resetForm() {
      this.appointmentData = { date: "", serviceType: "", location: "" };
      this.appointmentDetails = { firstName: "", lastName: "", email: "", phone: "", notes: "" };
      this.selectedService = null;
      this.availableServices = [];
      this.submitted = false;
    },
  },
  };
  </script>
  <style scoped>
  .card {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}
.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}
  </style>
  