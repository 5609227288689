<template>
    <div>
      <h1>Categories</h1>
      <p>Explore various categories on CityBits.</p>
      <!-- Categories listing goes here -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'CategoriesAll'
  };
  </script>
  
  <style scoped>
  /* Add category page-specific styles here */
  </style>
  