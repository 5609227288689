<template>
    <nav-bar></nav-bar>
    <breaking-news></breaking-news>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8" v-if="newsItem">
                <div class="news-detail-container">
                    <h2>{{ newsItem.title }}</h2>
                    <span class="date"><i class="fas fa-calendar"></i>{{ formattedDate(newsItem.published_at) }}</span>
                    <div class="d-flex align-items-start">
                      <img :src="newsItem.image" alt="News Image" class="img-fluid"/>
                    </div>  
                    <p>{{ newsItem.description }}</p>
                    <div>
                      <router-link v-if="category === 'activities'" class="btn btn-outline-primary btn-sm d-inline-block w-auto" to="/contact">Book Now</router-link>
                      <!-- <a v-if="category === 'activities'" :href="newsItem.url" target="_blank" class="fw-normal text-decoration-underline">Book Now</a> -->
                      <a v-else :href="newsItem.url" target="_blank" class="fw-normal text-decoration-underline">Read full article</a>                      
                    </div>
                </div>    
            </div>
            <div class="col-md-4">
                <h3><span class="bes-text-background">News in focus</span></h3>
                <div class="content-block d-flex mb-4" v-for="(content, index) in contentsColumn1" :key="index">
                    <a :href="`/news/${content.category}/${content.slug}`" class="d-flex align-items-center text-decoration-none">
                        <img :src="content.image" class="content-image img-fluid">          
                        <div class="content-text ms-3">
                            <h5>{{ content.title }}</h5>                    
                            <span class="date"><i class="fas fa-calendar"></i>{{ formattedDate(content.published_at) }}</span>
                        </div>
                    </a>    
                </div>
            </div>
        </div>    
    </div>
    <bes-news></bes-news>
    <footer-big></footer-big>
    <footer-bar></footer-bar>
</template>
<script>
import { db } from '@/firebase';
  import NavBar from '@/components/NavBar.vue';
  import BreakingNews from '@/components/BreakingNews.vue';  
  import FooterBar from '@/components/FooterBar.vue';
  import FooterBig from '@/components/FooterBig.vue';
  import BesNews from '@/components/BesNews.vue';
  
  export default {
    components: {
    NavBar,
    BreakingNews,    
    BesNews,
    FooterBar,    
    FooterBig
    },
    props: ['category', 'year', 'month', 'day', 'slug'],
    data() {
        return {
        newsItem: null,
        contentsColumn1: [],
        };
    },
    async created() {
        try {
        await this.fetchNewsItem();
        await this.fetchNewsFromFirebase();
        } catch (error) {
        console.error("Error in component created hook:", error);
        }        
    },
    methods: {
      async fetchNewsItem() {      
      const fullSlug = `${this.year}/${this.month}/${this.day}/${this.slug}`;
      const catdatabase = `${this.category}`;      

      // First, try to get the document from the cache
      const querySnapshot = await db.collection(catdatabase)
        .where('slug', '==', fullSlug)
        .get({ source: 'cache' }); // Fetch from cache
      
        if (!querySnapshot.empty) {
        this.newsItem = querySnapshot.docs[0].data();
      } else {
        // If not in cache or cache is outdated, fetch from server
        //console.log('Fetching from server as cache is empty or outdated');
        const querySnapshot = await db.collection(catdatabase)
          .where('slug', '==', fullSlug)
          .get({ source: 'server' }); // Fetch from server

        if (!querySnapshot.empty) {
          this.newsItem = querySnapshot.docs[0].data();
        } else {
          console.log('No such document!');
        }
      }
    },
        async fetchNewsFromFirebase() {
        try {
            const querySnapshot = await db.collection('general').orderBy('published_at', 'desc').limit(7).get();
            this.contentsColumn1 = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
            }));
        } catch (error) {
            console.error('Error fetching news from Firebase:', error);
            // Handle the error appropriately
        }
        },
        formattedDate(dateString) {
        const dateObject = new Date(dateString);
        return this.formatDate(dateObject);
        },
        formatDate(date) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
        },
        truncateText(text, limit) {
        const wordsArray = text.split(' ');
        if (wordsArray.length > limit) {
            return wordsArray.slice(0, limit).join(' ') + '...';
        }
        return text;
        }
    }
  }
</script>  
<style scoped>
a{
    text-decoration: none;
    color: inherit; /* To retain the text color */
    display: flex; /* Make the anchor tag a flex container */
    align-items: center; /* Align the items vertically in the center */
    width: 100%; /* Full width to make entire area clickable */
}
.date
{
  font-size:10px;
}
.fas.fa-calendar {
  color: #45bfde; /* Bootstrap primary color */
  margin-right: 5px; /* Spacing between icon and text */
}
.content-block {
    display: flex; /* This makes it a flex container */
    align-items: center; /* Aligns items vertically in the center */
}

.content-text {
  flex-grow: 1; /* Allows the text content to fill the remaining space */
}
h3
{
  padding:0px 0px 0px 0px;
  margin:10px 0px 20px 0px;
  border-bottom: #45bfde 2px solid;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 30px;
}
.bes-text-background
{
  background-color: #45bfde;
  color:#fff;
  padding:5px 15px 5px 15px;
  margin-bottom: 20px;
}
h5{
font-size: 17px;
font-weight: 600;
}
.content-image {
  height: 110px;
  object-fit: cover;
  max-width: 110px; /* Adjust as needed */
  flex-shrink: 0; /* Prevent the image from shrinking */
}

.content-text {
  flex-grow: 1; /* Allow the text content to take up the remaining space */
  margin-left: 5px; /* Adds some space between the image and text */
}
</style>