<template>
    <footer class="small-footer">
      <div class="container-fluid text-center">
        <div class="space">© 2024 CityBits. All rights reserved.</div>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterBar'
  };
  </script>
  
  <style scoped>
  .small-footer {
    background-color: #45bfde;
    color:#fff;
    font-size: 0.9em;   
  }
  .space
  {
    padding:5px 0 5px 0;
  }
  </style>
  