<template>
    <div>
      <nav-bar></nav-bar>
      <div class="d-flex justify-content-center align-items-center">
      <div class="space">
      <div class="row">
          <h3>Contact Us</h3>
          <p>Have questions or feedback? Contact us here.</p>
          <!-- Contact form or information goes here -->
          <form @submit.prevent="submitForm">
          <!-- Name -->
          <div class="mb-3">
            <label for="name" class="form-label">Full Name</label>
            <input type="text" class="form-control" id="name" v-model="form.name" required />
          </div>

          <!-- Email -->
          <div class="mb-3">
            <label for="email" class="form-label">Email address</label>
            <input type="email" class="form-control" id="email" v-model="form.email" required />
          </div>

          <!-- Message -->
          <div class="mb-3">
            <label for="message" class="form-label">Message</label>
            <textarea class="form-control" id="message" rows="4" v-model="form.message" required></textarea>
          </div>

          <!-- Submit Button -->
          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
        <div v-if="isSubmitted" class="mt-3 alert alert-success">
          Thank you for contacting us! We'll get back to you soon.
        </div>
      </div>  
    </div>  
  </div>
      <footer-big></footer-big>
      <footer-bar></footer-bar>
    </div>
  </template>
  
  <script>
  import NavBar from '@/components/NavBar.vue';
  import FooterBar from '@/components/FooterBar.vue';
  import FooterBig from '@/components/FooterBig.vue';

  export default {
    name: 'ContactUs',
    components: {
      NavBar,
      FooterBar,
      FooterBig,
    },
    data() {
    return {
      form: {
        name: '',
        email: '',
        message: ''
      },
      isSubmitted: false
    };
  },
  methods: {
    submitForm() {
      // Here you can process the form data (e.g., send it to a server)
      console.log('Form submitted:', this.form);

      // Show success message
      this.isSubmitted = true;

      // Reset form
      this.form.name = '';
      this.form.email = '';
      this.form.message = '';
    }
  }
  };
  </script>
  <style scoped>
  .space
  {
    padding:50px 0 50px 0;
  }
  </style>
  