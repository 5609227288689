<template>
  <div class="container-fluid">
        <div class="row">
          <div class="col-12 p-0">                
            <h3>Top Stories</h3>
          </div>
        </div>
        <div class="row">  
            <!-- <div class="col-7 p-0" v-for="item in newsi" :key="item.id">
              <div class="top-stories-left-image-container" :style="{ backgroundImage: 'url(' + item.image + ')' }">
                      <div class="top-stories-left-image-overlay"></div>
                      <div class="text-container">
                        <a :href="`/news/${item.category}/${item.slug}`">
                          <h1>Health</h1>                          
                          <span class="top-stories-image-big-title">{{ item.title }}</span>                        
                          <div class="date"> <i class="fas fa-calendar"></i>{{ formattedDate(item.published_at) }}</div>
                        </a>
                      </div>  
              </div>
            </div> -->
            <div class="col-7">
              <div class="row" v-for="(row, rowIndex) in processedContentsLeftBig" :key="rowIndex">
                <div class="col-12 p-0" v-for="(newsi, index) in row" :key="index">                
                  <div class="top-stories-left-image-container" :style="{ backgroundImage: 'url(' + newsi.image + ')' }">
                      <div class="top-stories-left-image-overlay"></div>
                      <div class="text-container-iii">
                        <a :href="`/news/${newsi.category}/${newsi.slug}`">
                          <h4>Health</h4>
                          <span class="top-stories-image-title">{{ newsi.title }}</span>
                          <div class="date"> <i class="fas fa-calendar"></i>{{ formattedDate(newsi.published_at) }}</div>
                        </a>
                      </div>  
                  </div>
                </div>                
              </div>
            </div>
            <div class="col-5">                
              <div class="row" v-for="(row, rowIndex) in processedContentsRightTop" :key="rowIndex">
                <div class="col-6 p-0" v-for="(newsii, index) in row" :key="index">                
                  <div class="top-stories-image-container" :style="{ backgroundImage: 'url(' + newsii.image + ')' }">
                      <div class="top-stories-image-overlay"></div>
                      <div class="text-container-iii">
                        <a :href="`/news/${newsii.category}/${newsii.slug}`">
                          <h4>Business</h4>
                          <span class="top-stories-image-title">{{ newsii.title }}</span>
                          <div class="date"> <i class="fas fa-calendar"></i>{{ formattedDate(newsii.published_at) }}</div>
                        </a>
                      </div>  
                  </div>
                </div>                
              </div>
              <div class="row" v-for="(row, rowIndex) in processedContents" :key="rowIndex">
                <div class="col-6 p-0" v-for="(newsiii, index) in row" :key="index">                
                  <div class="top-stories-image-container" :style="{ backgroundImage: 'url(' + newsiii.image + ')' }">
                      <div class="top-stories-image-overlay"></div>
                      <div class="text-container-iii">
                        <a :href="`/news/${newsiii.category}/${newsiii.slug}`">
                          <h4>Science</h4>
                          <span class="top-stories-image-title">{{ newsiii.title }}</span>
                          <div class="date"> <i class="fas fa-calendar"></i>{{ formattedDate(newsiii.published_at) }}</div>
                        </a>
                      </div>  
                  </div>
                </div>                
              </div>              
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { db } from '@/firebase';
  export default {
    name: 'NewsSection',
    data() {
    return {
      newsi: [],
      newsii: [],
      newsiii: []
    };
  },
  async created() {
    await this.fetchNewsFromFirebasei();
    await this.fetchNewsFromFirebaseii();
    await this.fetchNewsFromFirebaseiii();
  },
  methods: {
    async fetchNewsFromFirebasei() {
      try {
        const querySnapshot = await db.collection('health').orderBy('published_at', 'desc').limit(2).get();
        this.newsi = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      } catch (error) {
        console.error('Error fetching news from Firebase:', error);
        // Handle the error appropriately
      }
    },
    async fetchNewsFromFirebaseii() {
      try {
        const querySnapshot = await db.collection('business').orderBy('published_at', 'desc').limit(2).get();
        this.newsii = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      } catch (error) {
        console.error('Error fetching news from Firebase:', error);
        // Handle the error appropriately
      }
    },
    async fetchNewsFromFirebaseiii() {
      try {
        const querySnapshot = await db.collection('science').orderBy('published_at', 'desc').limit(4).get();
        this.newsiii = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      } catch (error) {
        console.error('Error fetching news from Firebase:', error);
        // Handle the error appropriately
      }
    },
    formattedDate(dateString) {
      const dateObject = new Date(dateString);
      return this.formatDate(dateObject);
    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString('en-US', options);
    },
  },
  computed: {
    processedContents() {
      let rows = [];
      for (let i = 0; i < this.newsiii.length; i += 4) {
        rows.push(this.newsiii.slice(i, i + 4));
      }
      return rows;
    },
    processedContentsRightTop() {
      let rows = [];
      for (let i = 0; i < this.newsii.length; i += 2) {
        rows.push(this.newsii.slice(i, i + 4));
      }
      return rows;
    },
    processedContentsLeftBig() {
      let rows = [];
      for (let i = 0; i < this.newsi.length; i += 2) {
        rows.push(this.newsi.slice(i, i + 4));
      }
      return rows;
    }
  },
    props: {
      title: String
    }
  };
  </script>
  
  <style scoped>
.text-container {
  position: absolute;
  bottom: 50px;
  left: 50px; /* Adjust as per your requirement */
  right: 50px; /* Adjust as needed to maintain padding on the right */
}
.text-container a{
  text-decoration: none;
  color: #fff;
}
.text-container-ii {
  position: absolute;
  bottom: 25px;
  left: 25px; /* Adjust as per your requirement */
  right: 25px; /* Adjust as needed to maintain padding on the right */
}
.text-container-ii a{
  text-decoration: none;
  color: #fff;
}
.text-container-iii {
  position: absolute;
  bottom: 25px;
  left: 25px; /* Adjust as per your requirement */
  right: 25px; /* Adjust as needed to maintain padding on the right */
}
.text-container-iii a{
  text-decoration: none;
  color: #fff;
}
.date {
  margin-top: 5px;
  font-size:10px;
  white-space: nowrap; /* Prevents wrapping */
  overflow: hidden; /* Ensures overflow text is hidden */
  text-overflow: ellipsis;
}  
 .fas.fa-calendar {
  color: #45bfde; /* Bootstrap primary color */
  margin-right: 5px; /* Spacing between icon and text */
}
.top-stories-image-container {
            position: relative;
            /* background: url('p1.jpg') no-repeat center center;  */
            background-size: cover;
            min-height: 235px; /* Adjust as per your requirement */
        }

        .top-stories-image-overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.7); /* Black background with opacity */
            overflow: hidden;
            width: 100%;
            height: 100%;
            transition: .5s ease;
        }

        .top-stories-image-container:hover .top-stories-image-overlay {
            background: rgba(0, 0, 0, 0.4); /* Lesser opacity on hover */
        }        
        .top-stories-image-big-title {
            font-size: 22px; /* Adjust as per your requirement */
        }
        .top-stories-image-title-right {
          font-size: 22px; /* Adjust as per your requirement */
        }
        .top-stories-image-title {         
            font-size: 14px; /* Adjust as per your requirement */
        }
.top-stories-left-image-container {
            position: relative;
            background-size: cover;
            min-height: 353px; /* Adjust as per your requirement */            
        }
        .top-stories-left-image-overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.6); /* Black background with opacity */
            overflow: hidden;
            width: 100%;
            height: 100%;
            transition: .5s ease;
        }
        .top-stories-left-image-container:hover .top-stories-left-image-overlay {
            background: rgba(0, 0, 0, 0.4); /* Lesser opacity on hover */
        }
        .top-stories-right-top-image-container {
            position: relative;
            background-size: cover;
            min-height: 235px; /* Adjust as per your requirement */
        }

        .top-stories-right-top-image-overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.7); /* Black background with opacity */
            overflow: hidden;
            width: 100%;
            height: 100%;
            transition: .5s ease;
        }

        .top-stories-right-top-image-container:hover .top-stories-right-top-image-overlay {
            background: rgba(0, 0, 0, 0.4); /* Lesser opacity on hover */
        }
h1{
            font-size: 16px; /* Adjust as per your requirement */
            background-color: #45bfde;
            padding: 5px 0px 5px 15px;
            width:103px;
            text-transform: uppercase;
        }
        h2{
            font-size: 14px; /* Adjust as per your requirement */
            background-color: #45bfde;
            padding: 5px 0px 5px 15px;
            width:98px;
            text-transform: uppercase;
        }  
        h4{
            font-size: 14px; /* Adjust as per your requirement */
            background-color: #45bfde;
            padding: 5px 0px 5px 15px;
            width:98px;
            text-transform: uppercase;
        }  
h3
{
  padding:0px 0px 0px 10px;
  margin:30px 0px 30px 0px;
  border-left: #45bfde 4px solid;
  font-weight: 600;
}              
  </style>
  