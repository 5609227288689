<template>
<div class="container-fluid">
    <div class="row">
      <div class="col-12 p-0">                
        <h3>Entertainment News</h3>
      </div>
    </div>
    <div class="row" v-for="(row, rowIndex) in processedContents" :key="rowIndex">
      <div class="col-md-3" v-for="(content, index) in row" :key="index">
        <div class="content-block">
          <img :src="content.image" :alt="`Image ${index}`" class="img-fluid">
          <h5>{{ content.title }}</h5>
          <span class="date"> <i class="fas fa-calendar"></i>{{ formattedDate(content.published_at) }}</span>
          <p>{{ truncateText(content.description, 20) }}</p>
          <a :href="`/news/${content.category}/${content.slug}`" class="readmore-national-news">Read More</a>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import { db } from '@/firebase';
export default{
    name: 'NationalNews',
    data() {
    return {
      contents: []
    };
  },
  async created() {
    await this.fetchNewsFromFirebase();
  },
  methods: {
    async fetchNewsFromFirebase() {
      try {
        const querySnapshot = await db.collection('entertainment').orderBy('published_at', 'desc').limit(8).get();
        this.contents = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      } catch (error) {
        console.error('Error fetching news from Firebase:', error);
        // Handle the error appropriately
      }
    },
    formattedDate(dateString) {
      const dateObject = new Date(dateString);
      return this.formatDate(dateObject);
    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString('en-US', options);
    },
    truncateText(text, limit) {
      const wordsArray = text.split(' ');
      if (wordsArray.length > limit) {
        return wordsArray.slice(0, limit).join(' ') + '...';
      }
      return text;
    },
  },
  computed: {
    processedContents() {
      let rows = [];
      for (let i = 0; i < this.contents.length; i += 4) {
        rows.push(this.contents.slice(i, i + 4));
      }
      return rows;
    }
  }
}
</script>
<style scoped>
h3
{
  padding:0px 0px 0px 10px;
  margin:30px 0px 30px 0px;
  border-left: #45bfde 4px solid;
  font-weight: 600;
}

.content-block {
  margin-bottom: 20px;
}

.content-block img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.content-block h5 {
margin-bottom: 10px;
}

.content-block p {
margin-bottom: 10px;
}

.readmore-national-news
{
    color:#000;
    text-decoration: none;
    font-weight: 600;
    font-size:12px;
}
.fas.fa-calendar {
  color: #45bfde; /* Bootstrap primary color */
  margin-right: 5px; /* Spacing between icon and text */
}
.date
{
  font-size:10px;
}
</style>