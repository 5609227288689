
//import 'bootstrap/dist/css/bootstrap.min.css';
//createApp(App).mount('#app')
//const app = createApp(App);

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import BootstrapVue3 from 'bootstrap-vue-next';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// Importing a global CSS file
//import '@/assets/style.css';

const app = createApp(App);
app.use(router);
app.use(BootstrapVue3);
app.mount('#app');
